angular.module( 'cakaren.terminy', [
])
.factory("TerminService", function (server,$q){
  return {
    getTermin: function(id){
      console.log("getting termin with id", id);
      if(!id){
        return $q.when({
          name: "",
          description: "",
          capacity: 0
        });
      }
      return server("getTermin", {id: id});
    },
    deleteTermin: function(id){
      return server("deleteTermin", {id: id});
    },
    saveTermin: function(termin){
      return server("saveTermin", {termin: termin});
    },
    listTerminy: function(id){
      return server("listTerminy", {id: id});
    },
    orderTermin: function(order){
      return server("submitTerminOrder", {order: order});
    }
  };
});