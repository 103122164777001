angular.module('cakaren.print', [])
.factory("rezervaciaPrint", function RezervaciaPrintFactory($window){

  return function RezervaciaPrint(rezervacie, day){

    var printRezervacia = function(rezervacia){
      var str = "";
      if(rezervacia){
        str+= moment(rezervacia.start).format("HH:mm");
        str+=" " ;
        str+="<strong>"+rezervacia.name+"</strong>";
        str+=(rezervacia.phone?(" (" + rezervacia.phone + ")"):"");
        str+=" "+rezervacia.duration+" min";
      }
      return str;
    };
    var printRezervacie = function(rezervacie){
      var r =  [];
      if(rezervacie){
        angular.forEach(rezervacie, function(rezervacia){
          r.push(printRezervacia(rezervacia));
        });
      }
      return r.join("<br>");
    };
    var pad = function(n, width, z) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    };

    var plan = [];
    var starthour = 7;
    var endhour = 14;
    angular.forEach(rezervacie, function(rezervacia){
      if(moment(rezervacia.start).startOf("day").diff(moment(day).startOf("day"), "days") === 0){
        var hour = rezervacia.start.getHours();
        hour = Math.min(Math.max(hour, starthour-1), endhour+1);
        if(!plan[hour]){
          plan[hour] = [];
        }
        plan[hour].push(rezervacia);
      }
    });
    var win = $window.open("");
    win.document.write("<style>body{font-family: sans-serif;} table{width:100%; border-collapse: collapse;} th{width: 5em;} tr{border-top: 1px solid black; border-bottom: 1px solid black; height:4em;} td{ vertical-align:top; } .footer{padding-top: 1em; text-align:right;}</style>");
    win.document.write("<h1>"+moment(day).format("dddd DD.MM.YYYY")+"</h1>");
    win.document.write("<table>");
    if(plan[starthour-1]){
      win.document.write("<tr><th></th><td>"+printRezervacie(plan[starthour-1])+"</td>");
    }
    for(var s=starthour; s <= endhour; s+=1){
      win.document.write("<tr><th>"+Math.floor(s)+":00</th><td>"+printRezervacie(plan[s])+"</td>");
    }
    if(plan[endhour+1]){
      win.document.write("<tr><th></th><td>"+printRezervacie(plan[endhour+1])+"</td>");
    }
    win.document.write("</table>");
    win.document.write("<div class='footer'>Dátum tlače: "+moment().format("DD.MM.YYYY")+"</div>");
  };

});