function platbaMobilom(params){
  console.log("platim", params);
  var $form = $("<form>").attr("method", "POST").attr("action", params.url);
  angular.forEach(params.data, function(value, key){
    $form.append($("<input>").attr("type", "text").attr("name", key).val(value));
  });
  $("body").append($form);
  $form.submit();
}

angular.module( 'cakaren.objednavka', [
  'ui.router',
  'ui.bootstrap',
  'cakaren.poskytovatel',
  'cakaren.error'
])

.config(function config( $stateProvider ) {
  $stateProvider
  .state( 'objednavka', {
    url: '/objednavka/{id:int}',
    views: {
      "main": {
        controller: 'ObjednavkaCtrl',
        template: require("./objednavka.tpl.html")}
    }
  })
  .state( 'objednavka-simple-cz', {
    url: '/objednavka-simple-cz/{id:int}',
    views: {
      "main": {
        controller: 'ObjednavkaWeekCtrl',
        template: require("./objednavka.simple.cz.tpl.html")}
    }
  });

})

.controller("ObjednavkaWeekCtrl", function($scope, $controller, poskytovatelService, $stateParams){
  $controller('ObjednavkaCtrl', {$scope: $scope});
  $scope.days = [];
  $scope.dayOffset = 0;

  var generateDays = function(start, count){
    var updateDay = function(day){
        return function(casy){
          day.casy = casy;
          day.loading = false;
        };
     };
    var r = [];
    for(var i = 0; i < count; i++){
      var day = {date: moment(start).add(i, "days").toDate(), casy:[], loading: true};
      r.push(day);
      poskytovatelService.findFreeSlots($stateParams.id, day.date, $scope.totalTime).then(updateDay(day));
    }
    return r;
  };

  var updateDays = function(){
    $scope.days = generateDays(moment().add($scope.dayOffset, "days").startOf("week").toDate(), 7);
  };

  $scope.$watch("dayOffset", function(offset){
    console.log("offset changed", offset);
    updateDays();
  });

})

.controller("ObjednavkaLongtermCtrl", function($scope, $controller, poskytovatelService, $stateParams){
  $controller('ObjednavkaCtrl', {$scope: $scope});
  $scope.days = [];
  $scope.dayOffset = 0;

  var generateDays = function(start, count){
    var updateDay = function(day){
        return function(casy){
          day.casy = casy;
          day.loading = false;
        };
     };
    var r = [];
    for(var i = 0; i < count; i++){
      var day = {date: moment(start).add(i, "days").toDate(), casy:[], loading: true};
      r.push(day);
      //poskytovatelService.findFreeSlots($stateParams.id, day.date, $scope.totalTime).then(updateDay(day));
    }
    return r;
  };

  var update = function(){
    poskytovatelService.findFreeDays($stateParams.id, new Date(), moment().add(3, "month").toDate(), $scope.totalTime)
    .then(function(data){
      $scope.freeDays = data;
    });
  };
  update();

  var updateDays = function(){
    $scope.days = generateDays(moment().add($scope.dayOffset, "days").startOf("week").toDate(), 7);
  };

  $scope.$watch("totalTime", function(total){
    console.log("total changed", total);
    update();
  });

  $scope.$watch("dayOffset", function(offset){
    console.log("offset changed", offset);
    updateDays();
  });

})

.controller("ObjednavkaCtrl", function($scope, poskytovatelService, $window, $state, $stateParams, errorHandler){
  $scope.objednavka = {start: null, vykony:{}};
  $scope.den = new Date();
  $scope.data = {cas: null};
  $scope.error = {};
  $scope.vykony = [];
  $scope.totalTime = 0;
  $scope.timesLoading = false;
  $scope.daysLoading = true;
  $scope.casy = [];
  $scope.freeDays = [];
  $scope.blockedDays = [];
  $scope.maxDaysAfter = undefined;

  var isFree = function(date){
    return $scope.freeDays.indexOf(moment(date).format("YYYY-MM-DD")) >= 0;
  };

  var isBlocked = function(date){
    var d = moment(date).format("YYYY-MM-DD");
    if(moment().startOf("day").isAfter(date)){
      return {nazov: "Dátum už uplynul"};
    }
    if($scope.maxDaysAfter && moment().add($scope.maxDaysAfter, "days").isBefore(date)){
      return {nazov: "Rezervácia je možná maximálne "+$scope.maxDaysAfter+" dní vopred"};
    }
    return $scope.blockedDays.find(function(day){
      return day.datum === d;
    });
  };

  $scope.dateOptions = {
    beforeShowDay: function(date){
        var blocked;
        if(blocked = isBlocked(date)){
          return [false, "date-blokovane", blocked.nazov || ""];
        }else if (isFree(date)) {
           return [true, "date-volno", "Voľný termín"];
        } else {
           return [true, "date-obsadene", "Obsadené"];
        }
    }
  };

  poskytovatelService.findFreeDays($stateParams.id, new Date(), moment().add(3, "month").toDate(), 1)
  .then(function(data){
    $scope.freeDays = data.days || [];
    $scope.blockedDays = data.blocked || [];
    $scope.maxDaysAfter = data.maxDaysAfter;
    // force calendar update
    $scope.dateOptions.update++;
    $scope.daysLoading = false;
  });

  poskytovatelService.getKontakt($stateParams.id).then(function(kontakt){
    $scope.poskytovatel = kontakt;
  });

  poskytovatelService.listOnlineVykony($stateParams.id).then(function(vykony){
    $scope.vykony = vykony;
    if(vykony.length == 1){
      vykony[0].selected = true;
    }
  }, function(err){
    if(err.is && err.is("NotFoundException")){
      errorHandler.goError(err);
    }
  });

  var refreshTimes = function(){
    $scope.data.cas = undefined;
    $scope.timesLoading = true;
    poskytovatelService.findFreeSlots($stateParams.id, $scope.den, $scope.totalTime).then(function(casy){
      $scope.timesLoading = false;
      $scope.casy = casy;
    });
  };

  $scope.$watch('vykony', function(vykony){
    var sum = 0;
    angular.forEach(vykony, function(vykon){
      if(vykon.selected){
        sum+=vykon.duration;
      }
    });
    $scope.totalTime = sum;
    refreshTimes();
  }, true);

  $scope.$watch('den', function(den){
    refreshTimes();
  });

  var getStart = function(){
    var start = new Date($scope.data.cas.date.getTime());
    start.setHours(parseInt($scope.data.cas.time.split(":")[0], 10));
    start.setMinutes(parseInt($scope.data.cas.time.split(":")[1], 10));
    return start;
  };

  var getSelectedVykony = function(){
    var vykony = [];
    angular.forEach($scope.vykony, function(vykon){
      if(vykon.selected){
        vykony.push(vykon.id);
      }
    });
    return vykony;
  };

  $scope.submitOrder = function(){
    $scope.error = {};
    if(!$scope.den){ $scope.error.den = true; }
    if(!$scope.data || !$scope.data.cas){ $scope.error.cas = true; }
    if(!$scope.objednavka.name){ $scope.error.name = true; }
    if(!$scope.objednavka.phone){ $scope.error.phone = true; }
    if(!$scope.objednavka.suhlas){ $scope.error.suhlas = true; }
    if(getSelectedVykony().length === 0){ $scope.error.vykony = true; }
    if(hasError($scope.error)){
      return;
    }

    var order = {
      poskytovatel_id: $stateParams.id,
      start: getStart(),
      vykony_id: getSelectedVykony(),
      name: $scope.objednavka.name,
      phone: $scope.objednavka.phone,
      email: $scope.objednavka.email,
      answer: $scope.objednavka.answer
    };

    poskytovatelService.submitOrder(order)
    .then(function(response){
      console.log("go to ", response.code);
      if(response.payment){
        console.log("zaplat", response.payment);
        platbaMobilom(response.payment);
      }else{
        $state.go("rezervaciaShow", {code:response.code});
      }
    })
    .then(null, function(error){
      console.log("got error in posservice", error);
      if(error.is && error.is("NoFreeSlotException")){
        $scope.error.slots = true;
        refreshTimes();
      }
    });
  };



})



;


