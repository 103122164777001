angular.module( 'cakaren.auth', [
  'ui.router',
  'ui.bootstrap',
  'ui.bootstrap.accordion'
])

.config(function config( $stateProvider ) {
  $stateProvider.state( 'login', {
    url: '/login',
    views: {
      "main": {
        controller: 'LoginCtrl',
        template: require("./login.tpl.html")}
    }
  });
})

.controller('AccountMenu', function($scope, AuthService){
  $scope.user = undefined;
  var refresh = function(){
    AuthService.getUser().then(function(user){
      $scope.user = user;
    });
  };
  $scope.$on("login-changed", function(){
    console.log("changed");
    refresh();
  });
  refresh();
})

.controller( 'LoginCtrl', function( $scope, $state, $rootScope, AuthService ) {
  $scope.user = null;
  $scope.error = {};
  $scope.changeDone = false;
  AuthService.getUser().then(function(user){
    console.log("loaded user", user);
    $scope.user = user;
  });
  $scope.email = "";

  $scope.doUserLogin = function(email, password){
    $scope.busy = true;
    $scope.passwordError = false;
    AuthService.logIn(email,password).then(function(user){
      $scope.user = user;
    }, function(err){
      console.log("login error ", err);
      $scope.passwordError = true;
    }).then(function(){
      $rootScope.$broadcast("login-changed");
      $scope.busy = false;
    });
  };

  $scope.doUserLogout = function(){
    AuthService.logOut().then(function(){
      $scope.user = undefined;
      $scope.changeDone = false;
      $rootScope.$broadcast("login-changed");
    });
  };

  $scope.doChangePassword = function(old, newp, newp2){
    $scope.error.change = {};
    if(newp != newp2){
      $scope.error.change.match = true;
      return;
    }
    $scope.busy = true;
    AuthService.changePassword(old, newp).then(function(result){
      if(result){
        $scope.changeDone = true;
        $scope.busy = false;
      }else{
        $scope.error.change.old = true;
      }
    }, function(err){
      $scope.busy = false;
    });


  };

})


.factory('AuthService', function($q, server){
  var user; //undefined = nevieme
  var userPromise;

  return {
    getUser: function(){
      if(user !== undefined){
        var d = $q.defer();
        d.resolve(user);
        return d.promise;
      }else{
        if(!userPromise){
          userPromise = server("getUser").then(function(data){
            user = data.user;
          }).then(function(){
            userPromise = undefined;
            return user;
          });
        }
        return userPromise;
      }
    },

    logIn: function(email, password){
      return server("login", {email:email, password:password}).then(function(data){
        if(data.user){
          user = data.user;
          return user;
        }else{
          throw "AuthError";
        }
      });
    },

    logOut: function(){
      user = null; // null = odhlaseny, undefined = nevieme
      return server("logout", {});
    },

    changePassword:function(oldpass, newpass){
      return server("changePassword", {oldpass:oldpass, newpass:newpass});
    }
  };
})

;