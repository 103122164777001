angular.module( 'cakaren.poskytovatel', [
  "cakaren"
])

.factory("poskytovatelService", function(server){
  return {
    listVykony: function(){
      return server("listVykony");
    },

    listOnlineVykony: function(poskytovatelId){
      return server("listOnlineVykony", {id: poskytovatelId});
    },

    listPracoviska: function(){
      return server("listPracoviska");
    },

    getPoskytovatel: function(){
      return server("getPoskytovatel");
    },

    getKontakt: function(poskytovatelId){
      return server("getKontakt", {id: poskytovatelId});
    },

    findFreeSlots: function(poskytovatelId, date, duration){
      return server("findFreeSlots", {
        id: poskytovatelId,
        date: date,
        duration: duration
      });
    },

    findFreeDays: function(poskytovatelId, start, end, duration){
      return server("findFreeDays", {
        id: poskytovatelId,
        start: start,
        end: end,
        duration: duration
      });
    },

    submitOrder: function(order){
      return server("submitOrder", {
        order: order
      });
    }

  };
})


;