var planovacSimpleLastDate;

function dateFromString(date){
  if(typeof date === 'string' || date instanceof String){
      date = moment(date, "D.M.YYYY").toDate();
  } 
  return date;
}

angular.module( 'cakaren.planovac', [
  'ui.router',
  'ui.bootstrap',
  "ui.date",
  'cakaren.rezervacia',
  "cakaren.print"
])
.config(function config( $stateProvider) {
  $stateProvider
  .state( 'planovac', {
    url: '/planovac?start',
    views: {
      "main": {
        controller: 'PlanovacCtrl',
        template: require("./planovac.tpl.html")}
    }
  })
  .state( 'planovac-simple', {
    url: '/planovac-simple?start',
    resolve: {
      poskytovatel: function(poskytovatelService){
        return poskytovatelService.getPoskytovatel();
      }
    },
    views: {
      "main": {
        controller: 'PlanovacSimpleCtrl',
        template: require("./planovac.simple.tpl.html")}
    }
  })
  ;
})
.controller("PlanovacSimpleCtrl", function($scope, $state, rezervaciaService, planovacService, $window, $uibModal, poskytovatel, $q, rezervaciaPrint){
  var step = poskytovatel.defaultDuration;
  var formatTooltip;
  $scope.datum = moment().startOf("month").toDate();
  if(planovacSimpleLastDate){
    $scope.datum = moment(planovacSimpleLastDate).startOf("month").toDate();
  }

  $scope.loading = false;
  $scope.saving = false;


  $scope.step = step;
  var blockedDays = [];

  $scope.nextMonth = function(){
    $scope.datum = moment($scope.datum).add(1, "month").toDate();
  };

  $scope.prevMonth = function(){
    $scope.datum = moment($scope.datum).add(-1, "month").toDate();
  };

  $scope.printSchedule = function(day){
    rezervaciaPrint(rezervacie, day);
  };

  var isBlocked = function(date){
    var d = moment(date).format("YYYY-MM-DD");
    return !!blockedDays.find(function(day){
      return day.datum == d;
    });
  };

  var isHoliday = function(date){
    var dn = moment(date).day();
    if(dn === 0 || dn === 6){
      return true;
    }
    return false;
  };



  var generateDays = function(startdate){
    var days = [];
    var inmonth = moment(startdate).daysInMonth();
    for(var i=1; i<=inmonth; i++){
      var date = moment(startdate).date(i);
      days.push({
        day: i,
        short: date.format("dd"),
        long: date.format("dddd"),
        date: date.toDate(),
        blocked: isBlocked(date),
        holiday: isHoliday(date)
      });
    }
    return days;
  };

  var pad = function(n, width, z) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  };

  var formatHour = $scope.formatHour = function(mins){
    return ""+Math.floor(mins/60)+":"+pad(mins%60,2);
  };

  var generateHours = function(){
    var hours = [];
    for(var h=7*60; h<15*60; h+=step){
      hours.push(h);
    }
    return hours;
  };

  var isSlotDirty = function(slot){
    var r = false;
    if(slot && slot.length){
      angular.forEach(slot, function(rezervacia){
        if(rezervacia && rezervacia.dirty){
          r = true;
        }
      });
    }
    return r;
  };

  var isSlotOverfull = function(slot){
    var r = false;
    if(slot && slot.length){
      angular.forEach(slot, function(rezervacia1){
        angular.forEach(slot, function(rezervacia2){
          if(rezervacia1 != rezervacia2){
            if(
              rezervacia1.start.getTime()+rezervacia1.duration*60000 > rezervacia2.start.getTime() && 
              rezervacia1.start.getTime() < rezervacia2.start.getTime()
            ){
              r = true;
            }
          }
        });
      });
    }
    return r;
  };

  var isSlotOnline = function(slot){
    var r = false;
    if(slot && slot.length){
      angular.forEach(slot, function(rezervacia){
        if(rezervacia && rezervacia.online){
          r = true;
        }
      });
    }
    return r;
  };

  var toMinutes = function(date){
    return date.getHours()*60+date.getMinutes();
  };

  var countStarting = function(slot, mins){
    var r = 0;
    if(slot && slot.length){
      angular.forEach(slot, function(rezervacia){
        var start = toMinutes(rezervacia.start);
        if(start >= mins && start < mins+step){
          r++;
        }
      });
    }
    return r;
  };


  var isSlotContinued = function(slot, mins){
    var r = false;
    if(slot && slot.length){
      angular.forEach(slot, function(rezervacia){
        if(rezervacia && toMinutes(rezervacia.start) < mins){
          r = true;
        }
      });
    }
    return r;
  };


  var slots = $scope.slots = [];
  var dirtyRezervacie = $scope.dirtyRezervacie = [];
  var rezervacie = [];
  $scope.slotInfo = [];

  var updateSlots = $scope.updateSlots = function(){
    slots.length = 0;
    dirtyRezervacie.length = 0;
    var monthStart = moment($scope.datum).startOf("month");
    var monthEnd = moment($scope.datum).endOf("month");


    angular.forEach(rezervacie, function(rezervacia){
      var start = moment(rezervacia.start).toDate();
      var den = start.getDate();
      var startslot = start.getHours()*60 + Math.floor(start.getMinutes()/(step))*step;
      var endslot = Math.floor((start.getHours()*60 + start.getMinutes() + rezervacia.duration - 1)/step)*step;
      if(rezervacia.dirty){
        dirtyRezervacie.push(rezervacia);
      }
      
      if(moment(start).isBefore(monthStart) || moment(start).isAfter(monthEnd)){
        console.log("ignore rezervacia", rezervacia);
        return;
      }

      for(var slot = startslot; slot <= endslot; slot += step){
        if(!slots[den]){
          slots[den] = [];
        }
        if(!slots[den][slot]){
          slots[den][slot] = [];
        }
        slots[den][slot].push(rezervacia);
      } 
    });

    angular.forEach($scope.dni, function(den){
      if(!$scope.slotInfo[den.day]){
        $scope.slotInfo[den.day] = [];
      }
      angular.forEach($scope.hodiny, function(hodina){
        $scope.slotInfo[den.day][hodina] = {
          free: true,
          full: false,
          overfull: false,
          dirty: false,
          continued: false,
          startCount: 0,
          count: 0
        };
        if(slots[den.day] && slots[den.day][hodina]){
          $scope.slotInfo[den.day][hodina] = {
            'free': !slots[den.day][hodina],
            'full': !!slots[den.day][hodina],
            'overfull': isSlotOverfull(slots[den.day][hodina]),
            'dirty': isSlotDirty(slots[den.day][hodina]),
            'continued': isSlotContinued(slots[den.day][hodina], hodina),
            'startCount': countStarting(slots[den.day][hodina], hodina),
            'count': slots[den.day][hodina].length,
            'tooltip': formatTooltip(slots[den.day][hodina]),
            'online': isSlotOnline(slots[den.day][hodina])
          };
        }
      });
    });


  };

  var reload = function(){
    var startDate = moment($scope.datum).startOf("month").toDate();
    var endDate = moment(startDate).endOf("month").toDate();
    $scope.loading = true;
    rezervaciaService.getPlan(startDate, endDate)
    .then(function(plan){
      $scope.dni = generateDays($scope.datum);
      $scope.hodiny = generateHours();
      blockedDays = plan.blokacie || [];

      var newList = [];
      var list = plan.rezervacie;
      angular.forEach(list, function(rezervacia){
        var vlozit = rezervacia;
        // ak su nejake z nacitanych dirty, nahradime ich
        angular.forEach($scope.dirtyRezervacie, function(dirtyRezervacia, i){
          if(rezervacia.id == dirtyRezervacia.id){
            vlozit = dirtyRezervacia;
            $scope.dirtyRezervacie.splice(i,1);
          }
        });
        newList.push(vlozit);
      });
      // pridame zvysok dirty listu
      angular.forEach($scope.dirtyRezervacie, function(dirtyRezervacia){
        newList.push(dirtyRezervacia);
      });
      rezervacie = newList;
      updateSlots();
      $scope.loading = false;
    });
  };

  reload();
  $scope.$watch("datum", function(){
    planovacSimpleLastDate = $scope.datum;
    reload();
  });


  $scope.save = function(){
    planovacService.setActive(undefined);
    $scope.saving = true;
    var list = [];
    var promises = [];
    angular.forEach(dirtyRezervacie, function(rezervacia){
      if(!rezervacia.id){
        promises.push(rezervaciaService.saveRezervacia(rezervacia).then(function(id){
          rezervacia.id = id;
          rezervacia.dirty = false;
        }).then(null, function(err){
          $window.alert("Chyba pri vytváraní novej rezervácie!");
        }));
      }else{
        list.push(rezervacia);
      }
    });

    promises.push(
      rezervaciaService.batchUpdate(list)
      .then(function(data){
        console.log("batch done");
        angular.forEach(data.saved, function(saved){
          angular.forEach($scope.dirtyRezervacie, function(rezervacia){
            if(rezervacia.id == saved.id){
              rezervacia.dirty = false;
            }
          });
        });
      })
      .then(null, function(err){
        $window.alert("Chyba pri ukladaní rezervácií!");
      })
    );

    $q.all(promises).then(function(results){
      $scope.saving = false;
      updateSlots();
    });

  };

  formatTooltip = function(rezervacie){
    if(rezervacie && rezervacie.length){
      var tips = [];
      angular.forEach(rezervacie, function(rezervacia){
        if(rezervacia){
          tips.push(moment(rezervacia.start).format("H:mm")+" "+rezervacia.name);
        }else{
          console.log("no rezervacia in slot", rezervacie);
        }
      });
      return tips.join(", ");
    }
    return undefined;
  };

  var openRezervaciaModal = function(rezervacia){
    var wRezervacia = angular.copy(rezervacia);
    planovacService.setActive(wRezervacia);
    var modalInstance = $uibModal.open({
      template: require("../rezervacia/rezervacia.edit.modal.tpl.html"),
      controller: 'RezervaciaModalCtrl as ctrl'
    });

    modalInstance.result.then(function (keepActive) {
      // save
      if(!rezervacia.id && !rezervacia.dirty){
        rezervacie.push(rezervacia);
      }
      rezervacia.dirty = true;
      rezervacia.name = wRezervacia.name;
      rezervacia.phone = wRezervacia.phone;
      rezervacia.duration = wRezervacia.duration;
      rezervacia.pracovisko_id = wRezervacia.pracovisko_id;
      rezervacia.note = wRezervacia.note;
      updateSlots();
      if(!keepActive){
        planovacService.setActive(undefined);
      }
    }, function (err) {
      // cancel
    });

  };



  $scope.slotClick = function(den, hodina){
    var rezervacia;
    if(slots && slots[den] && slots[den][hodina]){
      var slot = slots[den][hodina];
      if(slot && slot[0]){
        rezervacia = slot[0];
      }
    }
    if(!rezervacia){
      rezervacia = {
        name: "",
        phone: "",
        duration: step, //@todo default duration
        start: moment($scope.datum).startOf("month").date(den).hour(hodina/60).minutes(hodina%60).toDate()
      };
    }
    openRezervaciaModal(rezervacia);
  };

})
.controller("PlanovacCtrl", function($scope, $state, rezervaciaService, planovacService, $window, rezervaciaPrint){
  $scope.Math = Math;
  $scope.range = function(from,to){
    var a = [];
    for(var i=from; i<=to; i++){
      a.push(i);
    }
    return a;
  };

  $scope.rezervacie = [];
  $scope.dirtyRezervacie = [];
  $scope.blockedDays = [];
  $scope.places = [{}];
  $scope.showDays = 7;
  $scope.den = moment(new Date()).startOf("week").toDate();

  // hodina, ktorou ma zacinat a koncit planovac
  // @TODO nacitat od poskytovatela
  $scope.starthour = 7;
  $scope.endhour = 17;

  $scope.scrollTime = 6.9;
  var aktivna = planovacService.getActive();
  if(aktivna){
    $scope.rezervacie.push(aktivna);
    $scope.den = moment(aktivna.start).startOf("week").toDate();
    $scope.scrollTime = aktivna.start.getHours() - 0.1;
  }else{
    if(planovacService.getLastDate()){
      $scope.den = moment(planovacService.getLastDate()).startOf("week").toDate();
    }
  }


  var placeLookup = {};

  var loadRezervacie = function(date){
    date = dateFromString(date);
    planovacService.setLastDate(date);
    //vypocita rozsah datumov (date az date + 7 dni)
    var startDate = new Date(date.getTime()-5*24*3600*1000);
    startDate.setHours(0);
    startDate.setMinutes(0);
    var endDate = new Date(startDate.getTime()+14*24*3600*1000);

    rezervaciaService.getPlan(startDate, endDate)
    .then(function(plan){
      var newList = [];

      // blokacie
      $scope.blockedDays = plan.blokacie || [];

      // lookup tabulka pre pracoviska podla ID
      placeLookup = {};
      angular.forEach(plan.pracoviska, function(pracovisko, index){
        placeLookup[pracovisko.id] = index;
      });
      $scope.places = plan.pracoviska;

      var list = plan.rezervacie;
      angular.forEach(list, function(rezervacia){
        var vlozit = rezervacia;
        // ak su nejake z nacitanych dirty, nahradime ich
        angular.forEach($scope.dirtyRezervacie, function(dirtyRezervacia, i){
          if(rezervacia.id == dirtyRezervacia.id){
            vlozit = dirtyRezervacia;
            $scope.dirtyRezervacie.splice(i,1);
          }
        });
        newList.push(vlozit);
      });
      // pridame zvysok dirty listu
      angular.forEach($scope.dirtyRezervacie, function(dirtyRezervacia){
        newList.push(dirtyRezervacia);
      });
      return newList;
    })
    //zobrazime
    .then(function(list){
      $scope.rezervacie = list;
      $scope.computePositions();
    });
  };

  $scope.denOptions = angular.extend({},jQuery.datepicker.regional["sk"], {
    dateFormat: "dd.mm.yy"
  });

  $scope.reload = function(){loadRezervacie($scope.den);};

  $scope.prevDay = function(){
    $scope.den = new Date($scope.den.getTime()-24*3600*1000);
    $scope.computePositions();
    loadRezervacie($scope.den);
  };

  $scope.nextDay = function(){
    $scope.den = new Date($scope.den.getTime()+24*3600*1000);
    $scope.computePositions();
    loadRezervacie($scope.den);
  };

  $scope.isHoliday = function(date){
    var dn = date.getDay();
    if(dn === 0 || dn === 6){
      return true;
    }
    /*
    var m = date.getMonth()+1;
    var d = date.getDate();
    if(
      (m === 1 && ( d === 1 || d === 6)) ||
      (m === 5 && (d === 1 || d === 8 )) ||
      (m === 6 && d === 5) ||
      (m === 8 && d === 29) ||
      (m === 9 && (d === 1 || d === 15))   ||
      (m === 11 && (d === 1 || d === 17)) ||
      (m === 12 && (d === 24 || d === 25 || d === 26))
    ){
      return true;
    } 
    */
    return false;
  };

  $scope.isBlocked = function(date){
    var d = moment(date).format("YYYY-MM-DD");
    return !!$scope.blockedDays.find(function(day){
      return day.datum == d;
    });
  };

  $scope.printSchedule = function(day, pracovisko){
    rezervaciaPrint($scope.rezervacie, day);
    /*
    var plan = [];
    angular.forEach($scope.rezervacie, function(rezervacia){
      if(moment(rezervacia.start).startOf("day").diff(moment(day).startOf("day"), "days") === 0){
        var slot = Math.floor((rezervacia.start.getHours()*60 + rezervacia.start.getMinutes())/15);
        if(!plan[slot]){
          plan[slot] = [];
        }
        plan[slot].push(rezervacia);
      }
    });

    var printRezervacia = function(rezervacia){
      if(rezervacia){
        return rezervacia.name + (rezervacia.phone?(" (" + rezervacia.phone + ")"):"");
      }
      return "";
    };
    var printRezervacie = function(rezervacie){
      var r =  [];
      if(rezervacie){
        angular.forEach(rezervacie, function(rezervacia){
          r.push(printRezervacia(rezervacia));
        });
      }
      return r.join(",");
    };
    var pad = function(n, width, z) {
      z = z || '0';
      n = n + '';
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    };
    var win = window.open("");
    win.document.write("<h1>"+moment(day).format("DD.MM.YYYY")+"</h1>");
    win.document.write("<table style='width:100%;'>");
    for(var s=7*60/15; s < 12*60/15; s+=1){
      win.document.write("<tr><th>"+Math.floor(s/4)+":"+pad(15*(s%4),2)+"</th><td>"+printRezervacie(plan[s])+"</td><th>"+Math.floor((5*4+s)/4)+":"+pad(15*((5*4+s)%4),2)+"</th><td>"+printRezervacie(plan[s+5*4])+"</td></tr>");
    }
    win.document.write("</table>");
    */
  };

  $scope.goToEdit = function(id, start, pracovisko){
    $state.go("rezervaciaEdit", {id:id, start:start, goto:"planovac", "pracovisko":pracovisko});
  };

  $scope.toggleDayView = function(day){
    $scope.den = day;
    if($scope.showDays == 1){
      $scope.showDays = 7;
    }else{
      $scope.showDays = 1;
    }
    $scope.reload();
  };


  $scope.createNew = function(event){
    var offset =  jQuery(event.currentTarget).offset();
    var X = event.pageX - offset.left;
    var Y = event.pageY - offset.top;
    var slot = Math.floor(X/(jQuery(event.currentTarget).width() / ($scope.weekDays.length*$scope.places.length)));
    var minSinceMidnight = 24*60*Y/jQuery(event.currentTarget).height();
    var newDay = $scope.weekDays[Math.floor(slot / $scope.places.length)];
    var newPlaceIndex = Math.floor(slot % $scope.places.length);
    var d = new Date(+newDay);
    // snap to 5 minutes
    minSinceMidnight = Math.round(minSinceMidnight/5)*5;
    d.setHours(Math.floor(minSinceMidnight/60));
    d.setMinutes(Math.floor(minSinceMidnight%60));
    var pracovisko_id = $scope.places[newPlaceIndex].id;
    $scope.goToEdit(0, d.getTime(), pracovisko_id);
  };

  $scope.save = function(){
    planovacService.setActive(undefined);
    var list = [];
    angular.forEach($scope.dirtyRezervacie, function(rezervacia){
      if(!rezervacia.id){
        rezervaciaService.saveRezervacia(rezervacia).then(function(id){
          rezervacia.id = id;
          rezervacia.dirty = false;
        }).then(null, function(err){
          $window.alert("Chyba pri vytváraní novej rezervácie!");
        });
      }else{
        list.push({id: rezervacia.id, start:rezervacia.start, pracovisko_id:rezervacia.pracovisko_id});
      }
    });

    rezervaciaService.batchUpdate(list)
    .then(function(data){
      console.log("batch done");
      angular.forEach(data.saved, function(saved){
        angular.forEach($scope.dirtyRezervacie, function(rezervacia){
          if(rezervacia.id == saved.id){
            rezervacia.dirty = false;
          }
        });
      });
      $scope.computePositions();
    })
    .then(null, function(err){
      $window.alert("Chyba pri ukladaní rezervácií!");
    });
  };

  

  var generateWeek = function(weekStart){
    weekStart = dateFromString(weekStart);
    console.log("new week from", weekStart);
    var r = [];
    var startMoment = moment(weekStart);
    for(var i=0; i<$scope.showDays; i++){
      r.push(startMoment.clone().add(i, 'days').toDate());
    }
    console.log("generated new week", r);
    return r;
  };

  $scope.weekDays = generateWeek($scope.den);

  var computePositions = function(){
    var den = dateFromString($scope.den);
    $scope.weekDays = generateWeek(den);
    var dirtyRezervacie = [];
    var slotOccupied = {};
    var timeToMinutes = function(date){
      return date.getHours()*60 + date.getMinutes();
    };

    $scope.rezervacie.sort(function(a,b){
      return a.start.getTime()-b.start.getTime();
    });
    angular.forEach($scope.rezervacie, function(rezervacia){
      if(rezervacia.dirty){
        dirtyRezervacie.push(rezervacia);
      }
      
      var daySlot = moment(rezervacia.start).startOf("day").diff(moment(den).startOf("day"), "days");
      var placeSlot = placeLookup[rezervacia.pracovisko_id] || 0;
      var slot = -1;
      if(daySlot >= 0){
        slot = (daySlot*$scope.weekDays.length + placeSlot);
      }
      if(!slotOccupied[slot]){
        slotOccupied[slot] = 0;
      }
      rezervacia.top = 100*(rezervacia.start.getHours()+rezervacia.start.getMinutes()/60)/24;
      rezervacia.warning = false;
      if(slotOccupied[slot] > timeToMinutes(rezervacia.start)){
        rezervacia.warning = true;
      }
      rezervacia.left = (daySlot*100/$scope.weekDays.length + placeSlot*100/$scope.weekDays.length/$scope.places.length);
      rezervacia.height = 100*rezervacia.duration/60/24;
      slotOccupied[slot] = timeToMinutes(rezervacia.start)+rezervacia.duration;
    });
    $scope.dirtyRezervacie = dirtyRezervacie;
  };

  $scope.computePositions = computePositions;
  $scope.$watch("showDays", function(showDays, old){
    if(old == 1 && showDays == 7){
      $scope.den = moment($scope.den).startOf("week").toDate();
    }
    computePositions();
    loadRezervacie($scope.den);
  });
  loadRezervacie($scope.den);
  

})

.directive('planovacSlot', function(){
  var dragged = false;
  var getDragImage = function(){
    var img = new Image();
    return img;
  };

  return {
    restrict: 'A',
    scope:{
      planovacSlot: "=",
      slotDen: "=",
      slotHodina: "=",
      onUpdate: "&"
    },
    link: function(scope, _element, attrs){
      var element = jQuery(element);
      var dragDepth = 0;
      element.attr("draggable", "true");
      element.on("dragstart", function(event){
        dragDepth = 0;
        dragged = scope;
        event.originalEvent.dataTransfer.setData('text/plain', ' ');
        event.originalEvent.dataTransfer.setDragImage(getDragImage(),1,1);
        // skryt tooltipy
        // @TODO tento hnusny hack dat niekam inde
        jQuery(".tooltip.in").hide();
      });
      element.on("dragend", function(event){
        dragged = false;
      });
      element.on("dragenter", function(event){
        dragDepth++;
        element.css("background", "yellow");
        event.preventDefault();
      });
      element.on("dragleave", function(event){
        dragDepth--;
        if(dragDepth === 0){
          element.css("background", "");
        }
      });
      element.on("dragover", function(event){
        event.preventDefault();
      });
      element.on("drop", function(event){
        event.preventDefault();
        dragDepth = 0;
        element.css("background", "");
        console.log("drag&drop", dragged.slotDen, dragged.slotHodina, " to ", scope.slotDen, scope.slotHodina);
        var rezervacia = dragged.planovacSlot[dragged.slotDen][dragged.slotHodina][dragged.planovacSlot[dragged.slotDen][dragged.slotHodina].length-1];
        if(rezervacia){
          rezervacia.dirty = true;
          rezervacia.start = new Date(+rezervacia.start);
          rezervacia.start.setDate(scope.slotDen);
          rezervacia.start.setHours(scope.slotHodina/60);
          rezervacia.start.setMinutes(scope.slotHodina%60);
          scope.$apply(scope.onUpdate);
        }
      });
    }
  };
})

.directive('planovacDraggable', function($document) {
  return function(scope, element, attr) {
    var startX = 0, startY = 0, x = 0, y = 0;
    var originalPosition;
    var rez = scope.$eval(attr.planovacDraggable);
    var parW = element.parent().width();
    var parH = element.parent().height();
    var scrollElement = element.parents(".container-scroll");
    var originalScroll;

    element.on('mousedown', function(event) {
      event.preventDefault();
      originalPosition = element.position();
      rez.dragging = true;
      startX = event.screenX - originalPosition.left;
      startY = event.screenY - originalPosition.top;
      x = originalPosition.left;
      y = originalPosition.top;
      originalScroll = scrollElement.scrollTop();
      $document.on('mousemove', mousemove);
      $document.on('mouseup', mouseup);
      // skryt tooltipy
      // @TODO tento hnusny hack dat niekam inde
      jQuery(".tooltip.in").hide();
    });

    function mousemove(event) {
      y = event.screenY - startY - (originalScroll - scrollElement.scrollTop());
      x = event.screenX - startX;

      if(x < 0){
        x = 0;
      }
      
      var rez = scope.$eval(attr.planovacDraggable);
      rez.top =  y/parH*100;
      rez.left = x/parW*100;
      var d = new Date(+rez.start);
      var minSinceMidnight = 24*60*y/element.parent().height();
      d.setHours(Math.floor(minSinceMidnight/60));
      d.setMinutes(Math.floor(minSinceMidnight%60));
      d.setMinutes(Math.round(d.getMinutes()/5)*5);
      rez.dirty = true;
      rez.start = d;
      scope.$apply();
    }

    function mouseup() {
      var rez = scope.$eval(attr.planovacDraggable);
      $document.off('mousemove', mousemove);
      $document.off('mouseup', mouseup);
      rez.dragging = false;
      var slot = Math.floor((x+element.width()/2)/(parW / (scope.weekDays.length*scope.places.length)));
      var newDay = scope.weekDays[Math.floor(slot / scope.places.length)];
      if(newDay){
        var d = new Date(+newDay);
        d.setHours(rez.start.getHours());
        d.setMinutes(rez.start.getMinutes());
        rez.start = d;
      }
      var newPlaceIndex = Math.floor(slot % scope.places.length);
      if(scope.places[newPlaceIndex]){
        rez.pracovisko_id = scope.places[newPlaceIndex].id;
      }
      console.log("dropped to slot", slot, "new Day", newDay, "place", newPlaceIndex, "days", scope.weekDays);
      scope.$apply(scope.computePositions);
    }
  };
})

.directive('trackWidth', function($parse, $window){
  return {
    restrict: 'A',
    link: function(scope, element, attr){
      var getWidth = function(){
        return jQuery(element).width();
      };

      var onResize = function(){
        var w = getWidth();
        $parse(attr.trackWidth).assign(scope.$parent, w);
      };

      var applyOnResize = function(){
        scope.$apply(onResize);
      };

      $window.addEventListener('resize', applyOnResize);
      scope.$on('$destroy', function(){
        $window.removeEventListener('resize', applyOnResize);
      });

      scope.$watch(getWidth, function(width){
        onResize();
      });

      onResize();
    }
  };
})

.directive('scrollTime', function($parse, $window){
  return {
    restrict: 'A',
    link: function(scope, element, attr){
      scope.$watch(attr.scrollTime, function(scrollTime){
        element[0].scrollTop = (scrollTime-scope.starthour)/24 * element.prop("scrollHeight");
      });
    }
  };
})

.factory('planovacService', function(){
  var active;
  var lastDate;
  return {
    getLastDate: function(){
      return lastDate;
    },
    setLastDate: function(date){
      lastDate = date;
    },
    setActive: function(rezervacia){
      active = rezervacia;
    },
    getActive: function(){
      return active;
    }
  };
})

;
