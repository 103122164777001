angular.module( 'cakaren.dnes', [
  'ui.router',
  'ui.bootstrap',
  "ui.date",
  "angularMoment",
  "cakaren",
  "cakaren.rezervacia"
])
.value("dnesCache", {})

.constant("dnesConfig", {
  template: require("./dnes.tpl.html"),
  controller: "DnesCtrl"
})

.controller("DnesCtrl", function($scope, $state, rezervaciaService, dnesCache){
  $scope.RezervaciaStatus = RezervaciaStatus;
  $scope.rezervacie = dnesCache.rezervacie || [];
  $scope.goToPlanovac = function(){
    $state.go("planovac");
  };

  $scope.goToNovaRezervacia = function(){
    $state.go("rezervaciaEdit", {id:0, goto:null});
  };

  $scope.goToRezervacia = function(id){
    $state.go("rezervaciaPoskytovatel", {id:id});
  };

  $scope.goToEdit = function(id){
    $state.go("rezervaciaEdit", {id:id});
  };

  var update = function(){
    var start = new Date();
    start.setHours(0);
    start.setMinutes(0);
    var end = new Date(start.getTime() + 24*3600*1000);
    rezervaciaService.getRezervacie(start, end).then(function(list){
      $scope.rezervacie = list;
      dnesCache.rezervacie = list;
    });
  };

  $scope.$on("rezervacia-update", function(){
    console.log("updating");
    update();
  });

  update();

})

;