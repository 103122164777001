angular.module( 'cakaren.objednavka.taxi', [
  'cakaren.terminy',
  'cakaren.poskytovatel'
])
.config(function config( $stateProvider) {
  $stateProvider
  .state( 'objednavka-taxi', {
    url: '/objednavka/{id:int}/taxi',
    resolve: {
      terminy: function(TerminService, $stateParams){
        return TerminService.listTerminy($stateParams.id);
      },
      poskytovatel: function(poskytovatelService, $stateParams){
        return poskytovatelService.getKontakt($stateParams.id);
      }
    },
    views: {
      "main": {
        controller: 'ObjednavkaTaxiCtrl',
        controllerAs: "ctrl",
        template: require("./objednavka.taxi.tpl.html"),
      }
    }
  });
})
.controller("ObjednavkaTaxiCtrl", function(terminy, poskytovatel, TerminService, poskytovatelService, $state){
  var ctrl = this;
  ctrl.terminy = terminy;
  ctrl.poskytovatel = poskytovatel;
  ctrl.order = {
    nastup: "",
    vystup: "",
    name: "",
    phone: "",
    email: ""
  };

  ctrl.submitOrder = function(){
    ctrl.error = {};
    if(!ctrl.selectedTermin){ ctrl.error.termin = true; }
    if(!ctrl.order.name){ ctrl.error.name = true; }
    if(!ctrl.order.phone){ ctrl.error.phone = true; }
    if(!ctrl.order.suhlas){ ctrl.error.suhlas = true; }
    if(hasError(ctrl.error)){
      console.log(ctrl.error);
      return;
    }

    var order = {
      poskytovatel_id: poskytovatel.id,
      termin_id: ctrl.selectedTermin.id,
      name: ctrl.order.name,
      phone: ctrl.order.phone,
      email: ctrl.order.email,
      answer: ("Nástup: "+ctrl.order.nastup+" Výstup:"+ctrl.order.vystup)
    };

    TerminService.orderTermin(order)
    .then(function(response){
      console.log("go to ", response.code);
      if(response.payment){
        console.log("zaplat", response.payment);
        platbaMobilom(response.payment);
      }else{
        $state.go("rezervaciaShow", {code:response.code});
      }
    }, function(error){
      if(error && error.is && error.is("NoFreeSlotException")){
        ctrl.error.slots = true;
      }
    });
  };


});