angular.module( 'cakaren.terminy.edit', [
  'ui.router',
  'ui.bootstrap',
  "ui.date",
  'cakaren.terminy',
  'cakaren.terminy.rezervacia.edit'
])
.config(function config( $stateProvider) {
  console.log("registering state");
  $stateProvider
  .state( 'termin-edit', {
    url: '/termin/{id:int}',
    views: {
      "main": {
        controller: 'TerminEditCtrl',
        controllerAs: "ctrl",
        template: require("./termin.edit.tpl.html"),
        resolve: {
          termin: function(TerminService, $stateParams){
            return TerminService.getTermin($stateParams.id);
          },
          rezervacie: function(rezervaciaService, $stateParams){
            return rezervaciaService.getRezervacieByTerminId($stateParams.id);
          }
        }
      }
    }
  });
})
.config(function config( $stateProvider) {
  console.log("registering state");
  $stateProvider
  .state( 'termin-copy', {
    url: '/termin/{id:int}/copy',
    resolve: {
          termin: function(TerminService, $stateParams){
            return TerminService.getTermin($stateParams.id).then(function(termin){
              termin.id = 0;
              return termin;
            });
          },
          rezervacie: function(rezervaciaService, $stateParams){
            return rezervaciaService.getRezervacieByTerminId($stateParams.id);
          }
    },
    views: {
      "main": {
        controller: 'TerminEditCtrl',
        controllerAs: "ctrl",
        template: require("./termin.edit.tpl.html"),
      }
    }
  });
})
.controller("TerminEditCtrl", function(termin, rezervacie, TerminService, rezervaciaService, $state, $window, TerminRezervaciaModal){
  var ctrl = this;
  ctrl.termin = termin;
  ctrl.rezervacie = rezervacie;
  var openRezervacia = TerminRezervaciaModal;

  ctrl.save = function(){
    TerminService.saveTermin(ctrl.termin).then(function(termin){
      $state.go("termin-list");
    });
  };

  ctrl.remove = function(){
    if($window.confirm("Naozaj odstrániť termín " + ctrl.termin.name + " ?")){
      TerminService.deleteTermin(ctrl.termin.id).then(function(){
        $state.go("termin-list");
      }, function(){
        $window.alert("Termín sa nepodarilo odstrániť.");
      });
    }
  };

  ctrl.removeRezervacia = function(rezervacia){
    if($window.confirm("Naozaj odstrániť rezerváciu " + rezervacia.name + " ?")){
      rezervaciaService.deleteRezervacia(rezervacia.id).then(function(){
        $state.reload();
      });
    }
  };

  ctrl.editRezervacia = function(rezervacia){
    if(!rezervacia){
      rezervacia = {
        termin_id: termin.id
      };
    }
    rezervacia = angular.copy(rezervacia);
    openRezervacia(rezervacia).result.then(function(){
      return rezervaciaService.saveRezervacia(rezervacia);
    }).then(function(){
      $state.reload();
    });
  };

});