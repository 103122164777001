angular.module( 'cakaren.error', [
  'ui.router',
  'ui.bootstrap',
  "cakaren"
])

.config(function config( $stateProvider, dnesConfig, Layout) {
  $stateProvider
  .state( 'error', {
    url: '/error?info',
    views: {
      'main': {
        controller: 'ErrorCtrl',
        template: require("./error.tpl.html")}
    }
  });
})

.controller("ErrorCtrl", function($scope, $stateParams, $log, errorHandler, $window, $timeout){
  var errors = errorHandler.getErrors();
  $scope.errors = errors;

  $scope.back = function(){
    $window.history.back();
  };
  $timeout(function(){
    errorHandler.clearErrors();
  }, 100);


})

.factory("errorHandler", function($state){
  var errors = [];

  var o = {
    pushError: function(err){
      errors.push(err);
    },

    goError: function(err){
      console.log("go to error page");
      o.pushError(err);
      $state.go("error");
    },

    clearErrors: function(err){
      errors = [];
    },

    getErrors: function(){
      return errors;
    }
  };

  return o;
})

;