angular.module( 'cakaren.rezervacia', [
  'ui.router',
  'ui.bootstrap',
  "ui.date",
  "cakaren.layout",
  "cakaren.dnes",
  "cakaren.poskytovatel",
  'cakaren.planovac',
  "angularMoment",
  "cakaren",
  "ja.qr"
])

.config(function config( $stateProvider, dnesConfig, Layout) {
  $stateProvider
  .state( 'rezervaciaEdit', {
    url: '/rezervacia-edit/{id:int}?start&goto&pracovisko',
    views: {
      "layout": Layout.sidepanel,
      "side@rezervaciaEdit": dnesConfig,
      "main@rezervaciaEdit": {
        controller: 'RezervaciaEditCtrl',
        template: require("./rezervacia.edit.tpl.html")}
    }
  })
  .state( 'rezervaciaPoskytovatel', {
    url: '/rezervacia/{id:int}',
    views: {
      "layout": Layout.sidepanel,
      "side@rezervaciaPoskytovatel": dnesConfig,
      "main@rezervaciaPoskytovatel": {
        controller: 'RezervaciaPoskytovatelCtrl',
        template: require("./rezervacia.poskytovatel.tpl.html")}
    }
  })
  .state( 'rezervaciaShow', {
    url: '/rezervacia/kod/{code:[0-9a-zA-Z\\-]*}',
    views: {
      "main": {
        controller: 'RezervaciaKlientCtrl',
        template: require("./rezervacia.klient.tpl.html")}
    }
  })
  .state( 'rezervaciaDelete', {
    url: '/rezervacia-delete/{id:int}',
    views: {
      "layout": Layout.sidepanel,
      "side@rezervaciaDelete": dnesConfig,
      "main@rezervaciaDelete": {
        controller: 'RezervaciaDeleteCtrl',
        template: require("./rezervacia.delete.tpl.html")}
    }
  });
})

.controller("RezervaciaKlientCtrl", function($scope, rezervaciaService, $stateParams, $state, $log){
  $scope.Math = Math;
  $scope.busy = true;
  $scope.klientUrl = "";
  $scope.poskytovatel = {
  /*
    nazov: "Nazov poskytovatela",
    adresa: "Jilemnického 27",
    mesto: "Trenčín",
    lat: 0.0,
    lng: 0.0,
    email: "info@poskytovatel.sk",
    telefon: "0902102903"
  */
  };
  $scope.rezervacia = {
    loading: true
  };
  $scope.range = function(from,to){
    var a = [];
    for(var i=from; i<=to; i++){
      a.push(i);
    }
    return a;
  };
  $scope.pocet = 6;
  $scope.doReload = function(){
    rezervaciaService.getRezervaciaByCode($stateParams.code).then(function(rezervacia){
      $scope.poskytovatel = rezervacia.poskytovatel;
      $scope.rezervacia.loading = false;
      $scope.rezervacia.name = rezervacia.name;
      $scope.rezervacia.duration = rezervacia.duration;
      $scope.rezervacia.confirmed = rezervacia.confirmed;
      $scope.rezervacia.doklad = rezervacia.doklad;
      $scope.rezervacia.termin = rezervacia.termin;
      $scope.den = rezervacia.start;
      $scope.casOd = rezervacia.start;
      $scope.casDo = new Date(rezervacia.start.getTime() + rezervacia.duration*60000);
      $scope.vykony = rezervacia.vykony;
      $scope.pocet = rezervacia.queue;
      $scope.meno = rezervacia.name;
      $scope.kod = rezervacia.code;
      $scope.klientUrl = rezervaciaService.getClientUrl(rezervacia);
      if(rezervacia.eststart){
        $scope.showQueue = true;
        $scope.delay = (rezervacia.eststart.getTime() - rezervacia.start.getTime())/60000;
        $scope.getWaitingTime = function(){
          return (rezervacia.eststart.getTime() - new Date().getTime())/60000;
        };
      }else{
        $scope.showQueue = false;
      }
      $scope.busy = false;
    });
  };
  $scope.doReload();

})

.controller("RezervaciaPoskytovatelCtrl", function($scope, rezervaciaService, $stateParams, $state, server, RezervaciaStatus){
  $scope.RezervaciaStatus = RezervaciaStatus;
  $scope.workQueue = 0;
  $scope.klientUrl = "";

  var loadRezervacia = function(rezervacia){
    $scope.meno = rezervacia.name;
    $scope.vykony = rezervacia.vykony;
    $scope.den = rezervacia.start;
    $scope.casOd = rezervacia.start;
    $scope.casDo = new Date(rezervacia.start.getTime() + rezervacia.duration*60000);
    $scope.trvanie = rezervacia.duration;
    $scope.status = rezervacia.status;
    $scope.poznamka = rezervacia.note;
    $scope.kod = rezervacia.code;
    $scope.klientUrl = rezervaciaService.getClientUrl(rezervacia);
    $scope.email = rezervacia.email;
    $scope.phone = rezervacia.phone;
    $scope.online = rezervacia.online;
    //$scope.recalcCasDo();
  };

  var loadRezervaciaById = function(rezervaciaId){
    $scope.workQueue++;
    rezervaciaService.getRezervacia(rezervaciaId).then(loadRezervacia, function(err){
      if(err.is && err.is("NotFoundException")){
        alert("Not found");
      }
    }).then(function(){
      $scope.workQueue--;
    });
  };

  loadRezervaciaById($stateParams.id);

  $scope.goToEdit = function(){
    $state.go("rezervaciaEdit", {id: $stateParams.id});
  };

  $scope.updateVykon = function(vykon){
    $scope.workQueue++;
    if(vykon.done && $scope.status == RezervaciaStatus.WAITING){
      $scope.status = RezervaciaStatus.NOW;
      $scope.updateStatus(RezervaciaStatus.NOW);
    }
    rezervaciaService.updateVykon($stateParams.id, vykon.id, vykon.done)
    .then(function(){$scope.workQueue--;});
  };

  $scope.updateStatus = function(status){
    $scope.workQueue++;
    rezervaciaService.updateStatus($stateParams.id, status)
   .then(function(){$scope.workQueue--;});
  };


})

.controller("RezervaciaModalCtrl", function RezervaciaModalCtrl(planovacService, poskytovatelService, $scope, $state){
  var that = this;
  this.rezervacia = planovacService.getActive();
  poskytovatelService.getPoskytovatel().then(function(poskytovatel){
    that.vsetkyVykony = poskytovatel.vykony;
    that.pracoviska = poskytovatel.pracoviska;
    if(!that.rezervacia.pracovisko_id){
      that.rezervacia.pracovisko_id = poskytovatel.pracoviska[0].id;
    }
  });
  this.goToEdit = function(){
    $state.go("rezervaciaEdit", {id: this.rezervacia.id, goto:"planovac-simple"});
    $scope.$close(true);
  };
})


.controller( 'RezervaciaEditCtrl', function RezervaciaCtrl($scope, rezervaciaService, poskytovatelService, $stateParams, $log, $state, planovacService) {
  
  $scope.Math = Math;
  $scope.vykony = [];
  $scope.den = new Date();
  $scope.casOd = new Date();
  $scope.trvanie = 0;
  $scope.vsetkyVykony = [];
  $scope.pracoviska = [];
  $scope.error = {};
  $scope.novyVykon = "";

  $scope.recalcCasDo = function(){
    if($scope.casOd){
      $scope.casDo = new Date($scope.casOd.getTime() + $scope.trvanie*60000);
    }
  };

  if($stateParams.pracovisko){
    $scope.pracoviskoId = parseInt($stateParams.pracovisko, 10);
  }
  
  poskytovatelService.getPoskytovatel().then(function(poskytovatel){
    $scope.vsetkyVykony = poskytovatel.vykony;
    $scope.pracoviska = poskytovatel.pracoviska;
    if(!$scope.trvanie){
      $scope.trvanie = poskytovatel.defaultDuration;
    }
    if(!$scope.pracoviskoId){
      $scope.pracoviskoId = poskytovatel.pracoviska[0].id;
    }
  });

  var loadRezervacia = function(rezervacia){
    $scope.meno = rezervacia.name;
    $scope.vykony = rezervacia.vykony;
    $scope.den = rezervacia.start;
    $scope.casOd = rezervacia.start;
    $scope.trvanie = rezervacia.duration;
    $scope.poznamka = rezervacia.note;
    $scope.pracoviskoId = rezervacia.pracovisko_id;
    $scope.email = rezervacia.email;
    $scope.phone = rezervacia.phone;
    $scope.online = rezervacia.online;
    $scope.recalcCasDo();
  };

  var loadRezervaciaById = function(rezervaciaId){
    rezervaciaService.getRezervacia(rezervaciaId).then(loadRezervacia);
  };

  var dirty;
  if($stateParams.id){
    $scope.nova = false;
    dirty = planovacService.getActive();
    if(dirty && dirty.id == $stateParams.id){
      loadRezervacia(dirty);
    }else{
      loadRezervaciaById($stateParams.id);
    }
  }else{
    $scope.nova = true;
    dirty = planovacService.getActive();
    if(dirty){
      loadRezervacia(dirty);
    }
    if($stateParams.start){
      $scope.casOd = $scope.den = new Date(+$stateParams.start);
    }
  }

  $scope.denOptions = angular.extend({},jQuery.datepicker.regional["sk"], {
    dateFormat: "dd.mm.yy"
  });
  
 

  var recalcDuration = function(){
    if($scope.vykony && $scope.vykony.length > 0){
      var total = $scope.vykony.reduce(function(sum, vykon){
        return (parseInt(vykon.duration,10) || 0)+sum;
      },0);
      $scope.trvanie = total;
      $scope.recalcCasDo();
    }
  };

  if(dirty){
    recalcDuration();
    $scope.recalcCasDo();
  }else{      
    $scope.recalcCasDo();
  }


  $scope.selectVykon = function(item){
    $scope.novyVykonTrvanie = item.duration;
  };

  $scope.addVykon = function(){
    if($scope.novyVykon === ""){
      $scope.novyVykon = "Výkon";
    }
    if($scope.novyVykon){
      $scope.vykony.push({
        name: $scope.novyVykon.name || $scope.novyVykon,
        duration: parseInt($scope.novyVykonTrvanie, 10) || 0
      });
      $scope.novyVykon = "";
      $scope.novyVykonTrvanie = "";
    }
    recalcDuration();
  };

  $scope.removeVykon = function(index){
    $scope.vykony.splice(index, 1);
    recalcDuration();
  };

  $scope.goToDelete = function(){
    $state.go("rezervaciaDelete", {id: $stateParams.id});
  };

  var showSuccess = function(id){
    if($stateParams.goto=="planovac"){
      $state.go("planovac");
    }else if($stateParams.goto=="planovac-simple"){
      $state.go("planovac-simple");
    }else{
      $state.go("rezervaciaPoskytovatel", {id: id});
    }
  };

  var showError = function(error){
    alert(error);
  };

  var getRezervacia = function(){
    var start = new Date(+$scope.den);
    start.setHours($scope.casOd.getHours());
    start.setMinutes($scope.casOd.getMinutes());

    var rezervacia = {
      id: $stateParams.id,
      name: $scope.meno,
      duration: $scope.trvanie,
      start: start,
      vykony: $scope.vykony,
      note: $scope.poznamka,
      pracovisko_id: $scope.pracoviskoId,
      phone: $scope.phone,
      email: $scope.email,
      dirty: true
    };
    return rezervacia;
  };

  $scope.getRezervacia = getRezervacia;
  $scope.saveRezervacia = function(){
    planovacService.setActive(undefined);
    $scope.error = {};
    if(!$scope.meno){ $scope.error.meno = true; }
    if(+$scope.trvanie <= 0) { $scope.error.trvanie = true; }
    if(+$scope.trvanie > 1500) { $scope.error.trvanie = true; }
    if(!$scope.pracoviskoId){ $scope.error.pracovisko = true; }
    if(hasError($scope.error)){
      return;
    }
    var rezervacia = getRezervacia();
    planovacService.setLastDate(rezervacia.start);
    rezervaciaService
      .saveRezervacia(rezervacia)
      .then(showSuccess)
      .then(undefined, showError);
  };

  $scope.goToPlanner = function(){
    var r = getRezervacia();
    planovacService.setActive(r);
    $state.go("planovac");
  };

  $scope.goToShow = function(){
    $state.go("rezervaciaPoskytovatel", {id:$stateParams.id});
  };

})

.controller("RezervaciaDeleteCtrl", function($scope, $state, $stateParams, rezervaciaService, planovacService){
  var doReload = function(){
    rezervaciaService.getRezervacia($stateParams.id).then(function(rezervacia){
      $scope.den = rezervacia.start;
      $scope.casOd = rezervacia.start;
      $scope.casDo = new Date(rezervacia.start.getTime() + rezervacia.duration*60000);
      $scope.meno = rezervacia.name;
    });
  };
  doReload();

  $scope.goBack = function(){
    $state.go("rezervaciaPoskytovatel", {id:$stateParams.id});
  };

  $scope.doDelete = function(){
    rezervaciaService.deleteRezervacia($stateParams.id).then(function(){
      $state.go("rezervaciaEdit", {id:0});
    });
    planovacService.setActive(undefined);
  };

})

.factory("rezervaciaService", function($q, server, $rootScope, $window, $state){
  var hydrateRezervacia = function(r){
    r.start = moment(r.start).toDate();
    if(r.eststart){
      r.eststart = moment(r.eststart).toDate();
    }
    return r;
  };

  return {

    getRezervaciaByCode: function(code){
      return server("getRezervaciaByCode", {code: code}).then(hydrateRezervacia);
    },

    getRezervacia:function(rezervaciaId){
      return server("getRezervaciaById", {id: rezervaciaId}).then(hydrateRezervacia);
    },

    getRezervacie:function(start, end){
      return server("getRezervacie", {start: start, end:end}).then(function(list){
        return list.map(hydrateRezervacia);
      });
    },

    getRezervacieByTerminId: function(terminId){
      return server("getRezervacie", {termin: terminId}).then(function(list){
        return list.map(hydrateRezervacia);
      });
    },

    getPlan:function(start,end){
      return server("getPlan", {start: start, end:end}).then(function(plan){
        plan.rezervacie.map(hydrateRezervacia);
        return plan;
      });
    },

    saveRezervacia: function(rezervacia){
      return server("saveRezervacia", {rezervacia:rezervacia}).then(function(data){
        $rootScope.$broadcast("rezervacia-update");
        return data.id;
      });
    },

    confirmRezervacia: function(rezervacia_id){
      return server("confirmRezervacia", {id: rezervacia_id});
    },

    batchUpdate: function(list){
      return server("batchUpdateRezervacia", {data: list})
      .then(function(x){
        $rootScope.$broadcast("rezervacia-update");
        return x;
      });
    },

    updateVykon: function(rezervaciaId, vykonId, done){
      return server("setRezervaciaStatus", {
        rezervacia: {
          id: rezervaciaId,
          vykony: [
            {id: vykonId, done: done}
          ]
        }
      });
    },

    updateStatus: function(rezervaciaId, status){
      return server("setRezervaciaStatus", {
        rezervacia:{
          id: rezervaciaId,
          status: status
        }
      }).then(function(x){
        $rootScope.$broadcast("rezervacia-update");
        return x;
      });
    },

    deleteRezervacia: function(rezervaciaId){
      return server("deleteRezervacia", {
        id: rezervaciaId
      }).then(function(x){
        $rootScope.$broadcast("rezervacia-update");
        return x;
      });
    },

    getClientUrl: function(rezervacia){
      return $window.location.href.split("#")[0] + $state.href('rezervaciaShow', {code: rezervacia.code});
    }

  };
});

