angular.module( 'cakaren.nastavenia', [
  'ui.router',
  'ui.bootstrap',
  "cakaren"
])

.config(function config( $stateProvider, dnesConfig, Layout) {
  $stateProvider
  .state( 'nastavenia', {
    url: '/nastavenia',
    views: {
      'main': {
        controller: 'NastaveniaCtrl',
        template: require("./nastavenia.tpl.html")}
    }
  });
})

.controller("NastaveniaCtrl", function($scope, poskytovatelService, nastaveniaService, $window){
  $scope.workQueue = 0;
  $scope.casy = [];
  $scope.otvaracieHodiny = [];
  $scope.specialneHodiny = {};
  $scope.online = {};
  $scope.pracoviska = [];
  $scope.vykony = [];
  $scope.blokacie = [];
  $scope.dniMesiaca = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
  $scope.confirm = function(){
    return $window.confirm.apply($window, arguments);
  };

  for(var h=0; h<24; h++){
    $scope.casy.push(h+":00");
    $scope.casy.push(h+":30");
  }

  var loadPracoviska = function(){
    $scope.workQueue++;
    poskytovatelService.listPracoviska().then(function(list){
      $scope.pracoviska = list;
      $scope.workQueue--;
    });
  };

  var loadVykony = function(){
    $scope.workQueue++;
    poskytovatelService.listVykony().then(function(list){
      $scope.vykony = list;
      $scope.workQueue--;
    });
  };

  var loadKontakt = function(){
    $scope.workQueue++;
    nastaveniaService.getKontakt().then(function(kontakt){
      $scope.kontakt = kontakt;
      $scope.workQueue--;
    });
  };

  var loadBlokacie = function(){
    $scope.workQueue++;
    nastaveniaService.getBlokacie().then(function(list){
      $scope.blokacie = list;
      $scope.workQueue--;
    });
  };

  $scope.toggleSpecialne = function(pracovisko_id, den){
    if(! $scope.specialneHodiny[pracovisko_id]){
      $scope.specialneHodiny[pracovisko_id] = {};
    }
    if(! $scope.specialneHodiny[pracovisko_id][den]){
      $scope.specialneHodiny[pracovisko_id][den] = {};
    }

    $scope.specialneHodiny[pracovisko_id][den].closed = ! $scope.specialneHodiny[pracovisko_id][den].closed;

  };

  var getOtvaracieHodiny = function(){
    var hodiny = [];
    angular.forEach($scope.otvaracieHodiny, function(otvaracieDen){
      var den = [];
      angular.forEach(otvaracieDen, function(val, key){
        if(val){
          den.push(key);
        }
      });
      hodiny.push(den);
    });
    return hodiny;
  };

  var setOtvaracieHodiny = function(hodiny){
    $scope.otvaracieHodiny = [];
    angular.forEach(hodiny, function(den){
      var data = {};
      angular.forEach(den, function(val){
        data[val] = true;
      });
      $scope.otvaracieHodiny.push(data);
    });
  };

  $scope.saveHodiny = function(){
    $scope.workQueue++;
    nastaveniaService.saveOtvaracieHodiny(getOtvaracieHodiny()).then(function(){
      $scope.workQueue--;
    });
  };

  $scope.saveVykony = function(){
    $scope.workQueue++;
    nastaveniaService.saveVykony($scope.vykony).then(function(){
      $scope.workQueue--;
      loadVykony();
    });
  };

  $scope.savePracoviska = function(){
    $scope.workQueue++;
    nastaveniaService.savePracoviska($scope.pracoviska).then(function(){
      $scope.workQueue--;
      loadPracoviska();
    });
  };

  $scope.saveKontakt = function(){
    $scope.workQueue++;
    nastaveniaService.saveKontakt($scope.kontakt).then(function(){
      $scope.workQueue--;
      loadKontakt();
    });
  };

  $scope.saveBlokacie = function(){
    $scope.workQueue++;
    nastaveniaService.saveBlokacie($scope.blokacie).then(function(){
      $scope.workQueue--;
      loadBlokacie();
    });
  };

  $scope.workQueue++;
  nastaveniaService.getOtvaracieHodiny().then(setOtvaracieHodiny).then(function(){
    $scope.workQueue--;
  });

  loadVykony();
  loadPracoviska();
  loadKontakt();
  loadBlokacie();

})

.factory('nastaveniaService', function(server){
  return {
    saveOtvaracieHodiny: function(hodiny){
      return server("saveOtvaracieHodiny", hodiny);
    },

    getOtvaracieHodiny: function(){
      return server("getOtvaracieHodiny");
    },

    saveVykony: function(list){
      return server("updateVykony", {vykony: list});
    },

    saveKontakt: function(kontakt){
      return server("updateKontakt", {kontakt: kontakt});
    },

    savePracoviska: function(pracoviska){
      return server("updatePracoviska", {pracoviska: pracoviska});
    },

    getKontakt: function(){
      return server("getKontakt");
    },

    getBlokacie: function(){
      return server("getBlokacie").then(function(list){
        var r = [];
        angular.forEach(list, function(item){
          r.push({
            id: item.id,
            nazov: item.nazov,
            datum: moment(item.datum).toDate()
          });
        });
        return r;
      });
    },

    saveBlokacie: function(list){
      var blokacie = list.map(function(b){
        return {
          nazov: b.nazov,
          datum: moment(b.datum).format("YYYY-MM-DD")
        };
      });     
      return server("updateBlokacie", {blokacie: blokacie});
    }

  };
})

;