import jQuery from "jquery";
import angular from "angular";
import "angular-ui-date";
import "./home/home.js";
import "@uirouter/angularjs";
import "./rezervacia/rezervacia.js";
import "angular-ui-bootstrap";
import "./layout/layout.js";
import "./dnes/dnes.js";
import "angular-moment";
import "./poskytovatel/poskytovatel.js";
import "./planovac/planovac.js";
import "./planovac/planovac.print.js";
import "angular-qr";
import "./auth/auth.js";
import "./objednavka/objednavka.js";
import "./terminy/terminy.js";
import "./terminy/termin.edit.js";
import "./terminy/termin.rezervacia.edit.modal.js";
import "./terminy/termin.list.js";
import "./nastavenia/nastavenia.js";
import "./objednavka/objednavka.taxi.js";
import "./error/error.js";
import "../less/main.less";
import moment from "moment";
import "moment/locale/sk";


import 'jquery-ui-themes/themes/flick/theme.css';
import 'jquery-ui-themes/themes/flick/jquery-ui.css';

window.moment = moment;

var RezervaciaStatus = {
  WAITING: 0,
  NOW: 1,
  DONE: 2,
  CANCELLED: 3
};

window.RezervaciaStatus = RezervaciaStatus;
window.jQuery = jQuery;

var ServerError = function ServerError (err, method) {
  this.type = err.type;
  this.message = err.message;
  this.hierarchy = err.hierarchy;
  this.method = method;
  if(err.cause){
    this.cause = new ServerError(err.cause);
  }
  this.trace = err.trace;
  this.data = err;
};

ServerError.prototype.is = function(cls) {
  for(var i in this.hierarchy){
    if(this.hierarchy[i] == cls){
      return true;
    }
  }
  return false;
};

var hasError = function(err){
    var r = false;
    angular.forEach(err, function(e){
      if(e){
        r = true;
      }
    });
    return r;
  };
window.hasError = hasError;

var module = angular.module( 'cakaren', [
  'ui.date',
//  'templates-app',
//  'templates-common',
  'ngBoilerplate.home',
  'cakaren.rezervacia',
  'cakaren.planovac',
  'cakaren.auth',
  'cakaren.dnes',
  'cakaren.objednavka',
  'cakaren.objednavka.taxi',
  'cakaren.nastavenia',
  'cakaren.exception',
  'cakaren.error',
  'cakaren.terminy.edit',
  'cakaren.terminy.list',
  'ui.router',
  "angularMoment"
])

.run(function(amMoment) {
    amMoment.changeLocale('sk', {calendar:{
            sameDay: '[dnes]',
            nextDay: '[zajtra]',
            nextWeek: function () {
                switch (this.day()) {
                case 0:
                    return '[v nedeľu]';
                case 1:
                case 2:
                    return '[v] dddd';
                case 3:
                    return '[v stredu]';
                case 4:
                    return '[vo štvrtok]';
                case 5:
                    return '[v piatok]';
                case 6:
                    return '[v sobotu]';
                }
            },
            lastDay: '[včera]',
            lastWeek: function () {
                switch (this.day()) {
                case 0:
                    return '[minulú nedeľu]';
                case 1:
                case 2:
                    return '[minulý] dddd';
                case 3:
                    return '[minulú stredu]';
                case 4:
                case 5:
                    return '[minulý] dddd';
                case 6:
                    return '[minulú sobotu]';
                }
            },
            sameElse: 'L'
        }});
})

.config( function myAppConfig ( $locationProvider, $stateProvider, $urlRouterProvider ) {
  $urlRouterProvider.otherwise( '/home' );
   $locationProvider.hashPrefix('');
})

.run( function run ($interval, server) {
  $interval(function(){
    server("ping");
  }, 60*1000);
})

.controller( 'AppCtrl', function AppCtrl ( $scope, $location, $window) {
  $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams){
    if($window.ga){
      $window.ga('send', 'pageview', $location.path());
    }
    /*if ( angular.isDefined( toState.data) ) {
      $scope.pageTitle = toState.data.pageTitle + ' | ngBoilerplate' ;
    }*/
  });
})

.value("config", {
  api: "../server/index.php"
})
.constant("RezervaciaStatus", RezervaciaStatus)
.factory("server", function(config, $http){
  var token = null;

  var fn = function(method, params){
    console.log("calling",method,params);
    return $http.post(config.api, {
      method: method,
      params: params,
      token: token
    }).then(function(response){
      if(response.data.out){
        console.log(method+"(): output ", response.data.out);
      }
      if(response.data.error && response.data.error.type == "CSRFException"){
        console.log("repeat request with token");
        token = response.data.result.token;
        return fn(method, params);
      }
      if(response.data.error){
        console.log("application error", response.data.error);
        throw new ServerError(response.data.error, method);
      }
      if(typeof response.data === 'string'){
        console.log("server error", response.data);
        throw "ServerError " + response.data;
      }
      console.log(method+"(): result", response.data.result);
      return response.data.result;
    },function(err){
      throw ["Conection error",err];
    });
  };


  return fn;

});

angular.module('cakaren.exception', [
  
]).factory('$exceptionHandler', function ($window,$log,$injector) {
  var errorHandler;
  return function (exception, cause) {
    if(!errorHandler){
       errorHandler = $injector.get('errorHandler');
    }
    $log.error("uncaught exception", exception, cause);
    if(exception instanceof ServerError){
      if(exception.is("NotAuthorizedException")){
        errorHandler.goError(exception);
        return;
      }
    }
  };
 });
 

//Array.find polyfill
if (!Array.prototype.find) {
  Array.prototype.find = function(predicate) {
    'use strict';
    if (this == null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return undefined;
  };
}