angular.module( 'cakaren.terminy.list', [
  'ui.router',
  'ui.bootstrap',
  "ui.date",
  'cakaren.terminy'
])
.config(function config( $stateProvider) {
  $stateProvider
  .state( 'termin-list', {
    url: '/terminy',
    resolve: {
      terminy: function(TerminService){
        return TerminService.listTerminy();
      }
    },
    views: {
      "main": {
        controller: 'TerminListCtrl',
        controllerAs: "ctrl",
        template: require("./termin.list.tpl.html"),
      }
    }
  });
})
.controller("TerminListCtrl", function(terminy){
  var ctrl = this;
  ctrl.terminy = terminy;
});