angular.module( 'ngBoilerplate.home', [
  'ui.router',
  'cakaren.rezervacia'
])

.config(function config( $stateProvider ) {
  $stateProvider.state( 'home', {
    url: '/home',
    views: {
      "main": {
        controller: 'HomeCtrl',
        controllerAs: "ctrl",
        template: require("./home.tpl.html")}
    }
  });
})

.controller( 'HomeCtrl', function HomeController( $scope, $state, rezervaciaService ) {
  $scope.loading = false;
  $scope.error = false;
  this.showForm = false;
  this._showProviders = false;
  this.showProviders = function(){
    this._showProviders = true;
    $(".home-container ul").get(0).scrollIntoView();
  };
  
  $scope.submitCode = function(kod){
    $scope.loading = true;
    $scope.error = false;
    rezervaciaService.getRezervaciaByCode(kod).then(function(){
      $scope.loading = false;
      $state.go("rezervaciaShow", {code: kod});
    }, function(){
      $scope.loading = false;
      $scope.error = true;
    });
  };


})

;

