angular.module('cakaren.terminy.rezervacia.edit', [])
.controller("TerminRezervaciaModalCtrl", function(rezervacia){
  var ctrl = this;
  ctrl.rezervacia = rezervacia;
})
.factory("TerminRezervaciaModal", function($modal){
  return function(rezervacia){
    return $modal.open({
      template: require("./termin.rezervacia.edit.modal.tpl.html"),
      controller: 'TerminRezervaciaModalCtrl as ctrl',
      resolve: {
        rezervacia: function(){
          return rezervacia;
        }
      }
    });
  };
});